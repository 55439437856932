'use strict';

module.exports = () => {
    const $banner = document.querySelector('.js-promo-banner');
    const $bannerContainer = $banner.closest('.experience-assets-promoBanner');
    const $close = document.querySelector('.js-promo-banner-close');

    /**
    * Updates the margin of the banner container based on the banner's sticky state.
    * If the banner has the 'is-sticky' class, the margin-bottom of the banner container
    * is set to the height of the banner to ensure proper spacing.
    */
    const updateBannerMargin = () => {
        if ($banner.classList.contains('is-sticky')) {
            const bannerHeight = $banner.clientHeight;
            $bannerContainer.style.marginBottom = `${bannerHeight}px`;
        }
    };

    if (sessionStorage.getItem('promoBannerClosed') !== 'true') {
        $bannerContainer.classList.add('show-banner');
    }

    updateBannerMargin();

    $close.addEventListener('click', () => {
        $bannerContainer.classList.remove('show-banner');
        sessionStorage.setItem('promoBannerClosed', 'true');
    });

    window.addEventListener('resize', updateBannerMargin);
};
